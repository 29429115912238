<template>
  <base-section
    id="k-d-a-profile"
    class="secondary"
    space="48"
  >
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="10"
        >
          <div
            v-for="(item, i) in m_arrContents"
            :key="i"
          >
            <component
              :is="item.strComponent"
              v-if="item.htmlText"
              :class="getTextClasses(item.eType , ['text-center'])"
              v-html="item.htmlText"
            />
            <component
              :is="item.strComponent"
              v-else-if="item.strText"
              :class="getTextClasses(item.eType , ['text-center'])"
            >
              {{ item.strText }}
            </component>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import TextClass from '@/mixins/text-class'
  import BusinessDetails from '@/mixins/business-details'

  export default {
    name: 'KDAProfile',

    mixins: [TextClass, BusinessDetails],

    computed: {
      m_arrContents () {
        return [
          {
            strText: 'Profile',
            strComponent: 'p',
            eType: this.eContentType.TITLE_1,
          },
          {
            htmlText: this.g_htmlBusinessDescriptionShort,
            strComponent: 'p',
            eType: this.eContentType.SUBTITLE_2,
          },
        ]
      },
    },
  }
</script>
